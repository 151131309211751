import React, { useMemo } from 'react';

//helpers
import useTranslation from 'hooks/useTranslation';
import { ActionKeys } from 'components/Forms/TemplateForms/Onboarding/Components/SubmitButtons';
import { ContactModel } from 'typings/application/contact';
import { onboardingAPI } from 'api/onboarding/onboardingAPI';
import { onboardingAPIAdapter } from 'apiAdapters/onboarding/onboardingAPIAdapter';
import { useVerificationContext } from 'modules/Onboarding/Organization/Steps/ApplicationDocumentation/VerificationContext';

// components
import { Message } from '@ui';
import ApplicantInformationShortForm, {
  FormValuesModel,
} from '../../../../../../ApplicantInformationShortForm';

interface IProps {
  isViewOnly?: boolean;
  contact: ContactModel;
  onAction?: (actionType: ActionKeys | null) => void;
}

const ApplicantInformation = ({ isViewOnly, contact, onAction }: IProps) => {
  const { t } = useTranslation('common');
  const verificationContext = useVerificationContext();

  const initialFormValues = useMemo<FormValuesModel>(() => {
    let phoneNumbers = [];
    if (contact.phoneNumbers.length) {
      phoneNumbers = contact.phoneNumbers.map((e) => ({
        type: e.type,
        value: e.number,
        primary: {
          status: e.isPrimary,
          canEdit: !e.isPrimary,
        },
      }));

      const hasPrimary = phoneNumbers.some((e) => e.primary.status);
      if (!hasPrimary) {
        phoneNumbers[0].primary.status = true;
      }
    } else {
      phoneNumbers = [
        {
          type: null,
          value: '',
          primary: {
            status: true,
            canEdit: true,
          },
        },
      ];
    }

    let emails = [];
    if (contact.emails.length) {
      emails = contact.emails.map((e) => {
        return {
          type: e.type,
          value: e.address,
          primary: {
            status: e.isPrimary,
            canEdit: !e.isPrimary,
          },
        };
      });

      const hasPrimary = emails.some((e) => e.primary.status);
      if (!hasPrimary) {
        emails[0].primary.status = true;
      }
    } else {
      emails = [
        {
          type: null,
          value: '',
          primary: {
            status: true,
            canEdit: true,
          },
        },
      ];
    }

    return {
      isSave: false,
      submitActionType: null,
      displayTooltipsForPhoneAndEmailSections: true,
      firstName: contact.firstName || '',
      middleName: contact.middleName || '',
      lastName: contact.lastName || '',
      emails,
      phoneNumbers,
    };
  }, [contact]);

  const handleSubmit = async (values: FormValuesModel) => {
    await verificationContext?.handleVerificationTabSubmitWithLoader(
      async () => {
        const formattedBody =
          onboardingAPIAdapter.submitApplicantInformationIndividualWithNoKeyRelationshipStep(
            values,
          );
        await onboardingAPI.updateContactShortInfo(contact._id, formattedBody);
        Message.success(t('success_save'));
        onAction && onAction(values.submitActionType);
      },
    );
  };

  return (
    <ApplicantInformationShortForm
      disabled={isViewOnly}
      formRef={verificationContext?.refState}
      showOnlySaveButton
      initialFormValues={initialFormValues}
      onSubmit={handleSubmit}
    />
  );
};

export default ApplicantInformation;
