import React, { useEffect, useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';

// components
import { Button, FilterDropdownProps } from '@ui';

interface IProps {
  filterProps: FilterDropdownProps;
  children: React.ReactNode;
}

const FilterDropdownOverlay = ({ children, filterProps }: IProps) => {
  const { t } = useTranslation('common');

  useEffect(() => {
    if (filterProps && !filterProps.visible) {
      filterProps.confirm();
    }
  }, [filterProps.visible]);

  const hasValue = useMemo(() => {
    if (
      Array.isArray(filterProps.selectedKeys) &&
      !filterProps.selectedKeys.length
    ) {
      return false;
    }

    return true;
  }, [filterProps.selectedKeys]);

  return (
    <FilterDropdownWrapper onKeyDown={(e) => e.stopPropagation()}>
      <ContentWrapper>{children}</ContentWrapper>
      <FooterWrapper>
        <Button
          type="text"
          disabled={!hasValue}
          onClick={() => filterProps.clearFilters && filterProps.clearFilters()}
        >
          {t('reset')}
        </Button>
        <Button type="primary" onClick={() => filterProps.confirm()}>
          {t('ok')}
        </Button>
      </FooterWrapper>
    </FilterDropdownWrapper>
  );
};

const FilterDropdownWrapper = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column-reverse;
`;

const ContentWrapper = styled.div`
  padding: ${({ theme }) => theme.paddingSm};
`;

const FooterWrapper = styled.div`
  border-bottom: 1px solid rgba(198, 204, 207, 0.3);
  padding: ${({ theme }) => theme.paddingSm};

  display: flex;
  justify-content: flex-end;
`;

export default FilterDropdownOverlay;
