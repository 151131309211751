import * as yup from 'yup';
import {
  ContactAddressTypes,
  OrganizationAddressTypes,
} from '../../enums/onboarding/crm';
import {
  NULLABLE_BOOLEAN_VALIDATION_RULE,
  CRM_NAME_VALIDATION_RULE,
  STRING_VALIDATION_RULE,
  NULLABLE_DATE_VALIDATION_RULE,
  NULLABLE_ARRAY_VALIDATION_RULE,
  NULLABLE_STRING_VALIDATION_RULE,
} from '../common';

const addressValidator = yup.object().shape({
  type: NULLABLE_ARRAY_VALIDATION_RULE.of(STRING_VALIDATION_RULE),
  street: CRM_NAME_VALIDATION_RULE.max(50, {
    key: 'form:validation_messages.max_characters',
    values: {
      number: 50,
    },
  }),
  city: CRM_NAME_VALIDATION_RULE.max(20, {
    key: 'form:validation_messages.max_characters',
    values: {
      number: 20,
    },
  }),
  country: NULLABLE_STRING_VALIDATION_RULE.max(50, {
    key: 'form:validation_messages.max_characters',
    values: {
      number: 50,
    },
  }),
  state: CRM_NAME_VALIDATION_RULE.max(20, {
    key: 'form:validation_messages.max_characters',
    values: {
      number: 20,
    },
  }),
  postalCode: CRM_NAME_VALIDATION_RULE.max(10, {
    key: 'form:validation_messages.max_characters',
    values: {
      number: 10,
    },
  }),
  documents: yup.array().when('type', {
    is: (val: string[]) => val?.includes(ContactAddressTypes.PermanentAddress),
    then: (schema) =>
      schema
        .min(1, 'form:validation_messages.required')
        .required('form:validation_messages.required'),
  }),
});

const addressValidatorRequired = yup.object().shape({
  type: NULLABLE_ARRAY_VALIDATION_RULE.of(STRING_VALIDATION_RULE)
    .min(1, 'form:validation_messages.required')
    .required('form:validation_messages.required'),
  street: CRM_NAME_VALIDATION_RULE.max(50, {
    key: 'form:validation_messages.max_characters',
    values: {
      number: 50,
    },
  }).required('form:validation_messages.required'),
  city: CRM_NAME_VALIDATION_RULE.max(20, {
    key: 'form:validation_messages.max_characters',
    values: {
      number: 20,
    },
  }).required('form:validation_messages.required'),
  country: NULLABLE_STRING_VALIDATION_RULE.max(50, {
    key: 'form:validation_messages.max_characters',
    values: {
      number: 50,
    },
  }).required('form:validation_messages.required'),
  state: CRM_NAME_VALIDATION_RULE.max(20, {
    key: 'form:validation_messages.max_characters',
    values: {
      number: 20,
    },
  }).required('form:validation_messages.required'),
  postalCode: CRM_NAME_VALIDATION_RULE.max(10, {
    key: 'form:validation_messages.max_characters',
    values: {
      number: 10,
    },
  }).required('form:validation_messages.required'),
  documents: yup.array().when('type', {
    is: (val: string[]) => val?.includes(ContactAddressTypes.PermanentAddress),
    then: (schema) =>
      schema
        .min(1, 'form:validation_messages.required')
        .required('form:validation_messages.required'),
  }),
});

export const IdentificationStepForOrganizationShortValidationSchema = yup
  .object()
  .shape({
    submitActionType: yup.mixed().nullable(),
    isSave: NULLABLE_BOOLEAN_VALIDATION_RULE.defined(),

    isRegulated: NULLABLE_BOOLEAN_VALIDATION_RULE.when('isSave', {
      is: false,
      then: (schema) => schema.required('form:validation_messages.required'),
    }),

    regulationCountry: yup
      .array()
      .of(STRING_VALIDATION_RULE)
      .when('isRegulated', {
        is: true,
        then: (schema) =>
          schema.when('isSave', {
            is: false,
            then: (schema) =>
              schema.min(1, 'form:validation_messages.required'),
          }),
      }),
  });

export const IdentificationStepForOrganizationValidationSchema = yup
  .object()
  .shape({
    submitActionType: yup.mixed().nullable(),
    isSave: NULLABLE_BOOLEAN_VALIDATION_RULE.defined(),

    isRegulated: NULLABLE_BOOLEAN_VALIDATION_RULE.when('isSave', {
      is: false,
      then: (schema) => schema.required('form:validation_messages.required'),
    }),

    regulationCountry: yup
      .array()
      .of(STRING_VALIDATION_RULE)
      .when('isRegulated', {
        is: true,
        then: (schema) =>
          schema.when('isSave', {
            is: false,
            then: (schema) =>
              schema
                .min(1, 'form:validation_messages.required')
                .required('form:validation_messages.required'),
          }),
      }),

    canIssueBearerShares: NULLABLE_BOOLEAN_VALIDATION_RULE.when('isSave', {
      is: false,
      then: (schema) => schema.required('form:validation_messages.required'),
    }),

    addresses: yup.array().when('isSave', {
      is: true,
      then: (schema) => schema.of(addressValidator),
      otherwise: (schema) =>
        schema.of(addressValidatorRequired).test(
          'addresses',
          {
            key: 'form:validation_messages.provide_all_types_with_label',
            values: { label: 'common:addresses' },
          },
          (value) => {
            // Need to test if all address types were selected
            if (!value || !value.length) {
              return false;
            }

            let selectedTypes = '';

            // Generate a string with all selected types from each address section, e.g. 'business_address,registered_office,'
            value.forEach((e) => {
              selectedTypes += `${e?.type},`;
            });

            let isValid = true;

            // Loop through required address types
            // If we are missing at least 1 address from REQUIRED_ADDRESS_TYPES then we can return false (validation is not passed)
            for (const el of Object.values(OrganizationAddressTypes)) {
              if (!selectedTypes.includes(el)) {
                isValid = false;
                break;
              }
            }

            return isValid;
          },
        ),
    }),
  });

export const IdentificationStepForIndividualShortValidationSchema = yup
  .object()
  .shape({
    submitActionType: yup.mixed().nullable(),
    isSave: NULLABLE_BOOLEAN_VALIDATION_RULE.defined(),

    isPEP: NULLABLE_BOOLEAN_VALIDATION_RULE.when('isSave', {
      is: false,
      then: (schema) => schema.required('form:validation_messages.required'),
    }),

    pepInformation: STRING_VALIDATION_RULE.when('isPEP', {
      is: true,
      then: (schema) => schema.required('form:validation_messages.required'),
    }),
  });

export const IdentificationStepForIndividualValidationSchema = yup
  .object()
  .shape({
    submitActionType: yup.mixed().nullable(),
    isSave: NULLABLE_BOOLEAN_VALIDATION_RULE.defined(),

    isPEP: NULLABLE_BOOLEAN_VALIDATION_RULE.when('isSave', {
      is: false,
      then: (schema) => schema.required('form:validation_messages.required'),
    }),

    pepInformation: STRING_VALIDATION_RULE.when('isPEP', {
      is: true,
      then: (schema) => schema.required('form:validation_messages.required'),
    }),

    isRegulated: NULLABLE_BOOLEAN_VALIDATION_RULE.when('isSave', {
      is: false,
      then: (schema) => schema.required('form:validation_messages.required'),
    }),

    nationality: yup.array().when('isSave', {
      is: false,
      then: (schema) =>
        schema
          .of(STRING_VALIDATION_RULE)
          .required('form:validation_messages.required')
          .min(1, 'form:validation_messages.required'),
    }),

    regulationCountry: yup
      .array()
      .of(STRING_VALIDATION_RULE)
      .when('isRegulated', {
        is: true,
        then: (schema) =>
          schema.when('isSave', {
            is: false,
            then: (schema) =>
              schema.min(1, 'form:validation_messages.required'),
          }),
      }),

    addresses: yup.array().when('isSave', {
      is: true,
      then: (schema) => schema.of(addressValidator),
      otherwise: (schema) =>
        schema.of(addressValidatorRequired).test(
          'addresses',
          {
            key: 'form:validation_messages.provide_all_types_of_addresses_contact',
            values: { label: 'common:addresses' },
          },
          (value) => {
            // Need to test if all address types were selected
            if (!value || !value.length) {
              return false;
            }

            let selectedTypes = '';

            // Generate a string with all selected types from each address section, e.g. 'business_address,registered_office,'
            value.forEach((e) => {
              selectedTypes += `${e?.type},`;
            });

            let isValid = true;

            // Loop through required address types
            // If we are missing Mailing or Permanent addresses from REQUIRED_ADDRESS_TYPES then we can return false (validation is not passed)
            if (
              !selectedTypes.includes(ContactAddressTypes.MailingAddress) ||
              !selectedTypes.includes(ContactAddressTypes.PermanentAddress)
            ) {
              isValid = false;
            }

            return isValid;
          },
        ),
    }),

    passports: yup
      .array()
      .of(
        yup.object().shape({
          number: STRING_VALIDATION_RULE.min(2, {
            key: 'form:validation_messages.min_characters',
            values: {
              number: 2,
            },
          }).max(100, {
            key: 'form:validation_messages.max_characters',
            values: {
              number: 100,
            },
          }),
          country: STRING_VALIDATION_RULE,
          issuedAt: NULLABLE_DATE_VALIDATION_RULE,
          expirationDate: NULLABLE_DATE_VALIDATION_RULE.min(
            new Date(),
            'form:validation_messages.date_should_be_greater_than_today',
          ),
          document: yup.array(),
        }),
      )
      .when('isSave', {
        is: true,
        otherwise: () =>
          yup.array().of(
            yup.object().shape({
              number: STRING_VALIDATION_RULE.min(2, {
                key: 'form:validation_messages.min_characters',
                values: {
                  number: 2,
                },
              })
                .max(100, {
                  key: 'form:validation_messages.max_characters',
                  values: {
                    number: 100,
                  },
                })
                .required('form:validation_messages.required'),
              country: STRING_VALIDATION_RULE.required(
                'form:validation_messages.required',
              ),
              issuedAt: NULLABLE_DATE_VALIDATION_RULE.typeError(
                'form:validation_messages.required',
              ).required('form:validation_messages.required'),
              expirationDate: NULLABLE_DATE_VALIDATION_RULE.typeError(
                'form:validation_messages.required',
              )
                .min(
                  new Date(),
                  'form:validation_messages.date_should_be_greater_than_today',
                )
                .required('form:validation_messages.required'),
              document: yup
                .array()
                .min(1, 'form:validation_messages.required')
                .required('form:validation_messages.required'),
            }),
          ),
      }),
  });
