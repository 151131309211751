import React, { useEffect, useMemo, useState } from 'react';

// helpers
import styled from 'styled-components';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { TabsProps } from './types';
import { useDispatch } from 'react-redux';
import { colorsTheme } from 'resources/theme/styled/colors';
import { CHANGE_VIEW_TYPES, changeView } from 'redux/actions/view';

// components
import { Tabs as AntTabs } from 'antd';
import { ReactComponent as ArrowDownIcon } from 'resources/icons/remix-icons/arrow-down-s-line.svg';
import { Text, Button, IconSVG, Dropdown, MenuProps } from '@ui';

// Custom tabs component with additional config
const Tabs: React.FC<TabsProps> = ({
  items,
  defaultActiveKey,
  renderOnChange,
  activeKey,
  onChange,
  useChangeView,
  ...rest
}) => {
  const visibleTabs = useMemo(
    () => items.filter(({ hidden }) => !hidden),
    [items],
  );

  const [activeTab, setActiveTab] = useState(getInitialKey());

  const dispatch = useDispatch();
  const breakpoint = useBreakpoint();

  useEffect(() => {
    if (activeKey && activeKey !== activeTab) {
      setActiveTab(activeKey);
    }
  }, [activeKey]);

  function getInitialKey() {
    if (defaultActiveKey) {
      return defaultActiveKey;
    }

    return visibleTabs.length ? visibleTabs[0].key : '';
  }

  const handleTabChange = (newKey: string) => {
    if (useChangeView) {
      dispatch(
        changeView(() => {
          setActiveTab(newKey);
          onChange && onChange(newKey);
        }, CHANGE_VIEW_TYPES.TABS),
      );
    } else {
      setActiveTab(newKey);
      onChange && onChange(newKey);
    }
  };

  const getActiveTabTitle = (activeKey: string) => {
    return visibleTabs.find((e) => e.key === activeKey)?.label || '';
  };

  // Generate items for Ant Design Tabs
  const tabItems = visibleTabs.map(({ key, label, children }) => ({
    key: key.toString(),
    label,
    children,
  }));

  // Dropdown menu items for mobile view
  const dropdownItems: MenuProps['items'] = visibleTabs.map(
    ({ key, label }) => ({
      key,
      label,
      onClick: () => handleTabChange(key),
    }),
  );

  const renderTabBar = (props: any, DefaultTabBar: any) => {
    if (breakpoint.md) {
      // render default mobile header tabs
      // set mobile as a true value to allow scrolling tabs horizontally
      return <DefaultTabBar {...props} mobile />;
    } else {
      return (
        <Dropdown menu={{ items: dropdownItems }}>
          <ActiveTabButton size="large" type="bordered">
            <Text variant="body1">{getActiveTabTitle(activeTab)}</Text>
            <StyledIconSVG
              component={ArrowDownIcon}
              color={colorsTheme.colorWhite}
            />
          </ActiveTabButton>
        </Dropdown>
      );
    }
  };

  return (
    <StyledAntTabs
      {...rest}
      isTabsOne={visibleTabs.length === 1}
      activeKey={activeKey || activeTab}
      onChange={handleTabChange}
      items={tabItems}
      renderTabBar={renderTabBar}
      destroyInactiveTabPane={renderOnChange}
    />
  );
};

const StyledAntTabs = styled(AntTabs)<{ isTabsOne: boolean }>`
  .ant-tabs-nav {
    display: ${({ isTabsOne }) => (isTabsOne ? 'none' : 'flex')};
  }

  .ant-tabs-content {
    display: block;
  }
`;

const ActiveTabButton = styled(Button)`
  padding: 0px;
  position: relative;

  background-color: ${({ theme }) => theme.colorDarkL3};
  height: 50px;
`;

const StyledIconSVG = styled(IconSVG)`
  margin-left: ${({ theme }) => theme.marginXs};
`;

export default Tabs;
