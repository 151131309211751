import React, { useState } from 'react';

// helpers
import useFetch from 'hooks/useFetch';
import useTranslation from 'hooks/useTranslation';
import { Moment } from 'moment';
import { transactionsAPI } from 'api/accounting/transactionsAPI';
import { DEFAULT_TABLE_LIMIT } from 'constants/global';
import { TransactionStatuses } from 'enums/accounts/accounts';
import { FILTER_BAR_FIELDS_GRID_SIZES } from 'constants/grids';
import { convertAmountFromIntToBigInt } from 'helpers/accountsHelpers';

// components
import PendingTransactionsTable from './PendingTransactionsTable';
import { Divider, SortProps, Row, Col, SearchInput, SortOrder } from '@ui';

const PendingTransactions = () => {
  const { t } = useTranslation();
  const [currentPage, setPage] = useState(1);
  const [updateTableTrigger, updateTable] = useState({});
  const [searchQuery, setSearchQuery] = useState('');
  const [activeSorter, setActiveSorter] = useState<{
    key: 'beneficiaryAccountName' | 'beneficiaryAccountNumber' | undefined;
    direction: SortOrder | undefined;
  } | null>(null);
  const [activeFilters, setActiveFilters] = useState<{
    status: TransactionStatuses[] | null;
    from_account: string | null;
    requested_date: [Moment, Moment] | null;
    value_date: [Moment, Moment] | null;
    amount: number[] | null;
    currency: number[] | null;
    type: number[] | null;
    beneficiary_account_name: string[] | null;
    beneficiary_account_number: string[] | null;
  }>({
    status: [
      TransactionStatuses.Pending,
      TransactionStatuses.Processing,
      TransactionStatuses.Scheduled,
    ],
    from_account: null,
    requested_date: null,
    value_date: null,
    amount: null,
    currency: null,
    type: null,
    beneficiary_account_name: null,
    beneficiary_account_number: null,
  });

  const { response, loading } = useFetch(
    () =>
      transactionsAPI.fetchPendingTransactions({
        page: currentPage,
        limit: DEFAULT_TABLE_LIMIT,
        search: searchQuery.trim(),
        accountNumber: activeFilters.from_account || '',
        statusGroups:
          activeFilters.status && activeFilters.status.length
            ? activeFilters.status.join(',')
            : '',
        fromRequestedDate:
          activeFilters.requested_date && activeFilters.requested_date[0]
            ? activeFilters.requested_date[0].startOf('day').unix()
            : undefined,
        toRequestedDate:
          activeFilters.requested_date && activeFilters.requested_date[1]
            ? activeFilters.requested_date[1].endOf('day').unix()
            : undefined,
        fromValueDate:
          activeFilters.value_date && activeFilters.value_date[0]
            ? activeFilters.value_date[0].startOf('day').unix()
            : undefined,
        toValueDate:
          activeFilters.value_date && activeFilters.value_date[1]
            ? activeFilters.value_date[1].endOf('day').unix()
            : undefined,
        fromAmount:
          activeFilters.amount && activeFilters.amount[0]
            ? convertAmountFromIntToBigInt(activeFilters.amount[0])
            : undefined,
        toAmount:
          activeFilters.amount && activeFilters.amount[1]
            ? convertAmountFromIntToBigInt(activeFilters.amount[1])
            : undefined,
        currency: activeFilters.currency
          ? activeFilters.currency.join(',')
          : '',
        sourceTypes: activeFilters.type ? activeFilters.type.join(',') : '',
        beneficiaryAccountNames: activeFilters.beneficiary_account_name
          ? activeFilters.beneficiary_account_name.join(',')
          : undefined,
        beneficiaryAccountNumbers: activeFilters.beneficiary_account_number
          ? activeFilters.beneficiary_account_number.join(',')
          : undefined,
        sortBy: activeSorter ? activeSorter.key : undefined,
        sortDirection: activeSorter
          ? activeSorter.direction === 'ascend'
            ? false
            : true
          : undefined,
      }),
    [currentPage, searchQuery, activeFilters, activeSorter, updateTableTrigger],
  );

  const onFilterChange = (
    filters: Record<
      string,
      (boolean | React.Key | unknown)[] | React.Key | null
    >,
  ) => {
    setActiveFilters({
      from_account: (filters.from_account as string) || null,
      status: filters.status as TransactionStatuses[],
      requested_date: (filters.requested_date as [Moment, Moment]) || null,
      value_date: (filters.value_date as [Moment, Moment]) || null,
      amount: (filters.amount as number[]) || null,
      currency: (filters.currency as number[]) || null,
      type: (filters.type as number[]) || null,
      beneficiary_account_name:
        (filters.beneficiary_account_name as string[]) || null,
      beneficiary_account_number:
        (filters.beneficiary_account_number as string[]) || null,
    });
  };

  const updateTableValues = (switchToFirstPage?: boolean) => {
    if (switchToFirstPage) {
      setPage(1);
    }

    updateTable({});
  };

  const handleSortChange = (sort: SortProps) => {
    switch (sort.columnKey) {
      case 'beneficiary_account_name':
        {
          setActiveSorter((prevState) =>
            sort.order
              ? {
                  ...prevState,
                  key: 'beneficiaryAccountName',
                  direction: sort.order,
                }
              : null,
          );
        }
        break;

      case 'beneficiary_account_number':
        setActiveSorter((prevState) =>
          sort.order
            ? {
                ...prevState,
                key: 'beneficiaryAccountNumber',
                direction: sort.order,
              }
            : null,
        );
        break;

      case undefined:
        setActiveSorter((prevState) =>
          sort.order
            ? {
                ...prevState,
                key: undefined,
                direction: undefined,
              }
            : null,
        );
        break;
    }
  };

  return (
    <>
      <Row>
        <Col {...FILTER_BAR_FIELDS_GRID_SIZES}>
          <SearchInput
            onSearch={setSearchQuery}
            size="large"
            placeholder={t(
              'pending_transactions.table.filter_bar.search_placeholder',
            )}
          />
        </Col>
      </Row>
      <Divider />
      <PendingTransactionsTable
        total={response?.total || 0}
        current={currentPage}
        loading={loading}
        data={response?.data || []}
        onPaginationChange={setPage}
        filters={activeFilters}
        updateTableCallback={updateTableValues}
        onFilterChange={onFilterChange}
        onSortChange={handleSortChange}
      />
    </>
  );
};

export default PendingTransactions;
