import React, { useMemo } from 'react';

// helpers
import useFetch from 'hooks/useFetch';
import { transferTemplatesAPI } from '../../../../../../api/accounting/transferTemplatesAPI';

// components
import { FormSelect, SelectOption, SelectProps } from '@ui';

export interface CurrencyAutocompleteProps extends SelectProps<string> {
  chain?: string;
}

const BlockchainCurrencySelect = ({
  chain,
  ...rest
}: CurrencyAutocompleteProps) => {
  const { response, loading } = useFetch(
    () => transferTemplatesAPI.fetchCurrenciesByNetwork(chain),
    [chain],
  );

  const options = useMemo<SelectOption<string>[]>(() => {
    if (!response) {
      return [];
    }

    return response.map((e) => ({
      id: e,
      label: e,
      model: e,
    }));
  }, [response]);

  return <FormSelect {...rest} options={options} loading={loading} />;
};

export default BlockchainCurrencySelect;
