import * as Yup from 'yup';
import { ResetTabs } from '../../modules/Auth/ResetPassword';
import { OnboardingEntryTypes } from '../../enums/onboarding/crm';
import {
  CRM_CONTACT_NAME_MAX_LENGTH,
  NAME_MIN_LENGTH,
} from '../../constants/global';
import {
  EMAIL_VALIDATION_RULE,
  NULLABLE_STRING_VALIDATION_RULE,
  OTP_VALIDATION_RULE,
  PASSWORD_VALIDATION_RULE,
  CRM_NAME_VALIDATION_RULE,
  STRING_VALIDATION_RULE,
  PHONE_ASYNC_VALIDATION_RULE,
  NULLABLE_DATE_VALIDATION_RULE,
} from '../common';

export const EmailValidationSchema = Yup.object().shape({
  email: EMAIL_VALIDATION_RULE.required('form:validation_messages.required'),
});

export const UsernameValidationSchema = Yup.object().shape({
  // TODO: add special validation schema for username
  username: STRING_VALIDATION_RULE.required(
    'form:validation_messages.required',
  ),
});

export const PasswordValidationSchema = Yup.object().shape({
  password: PASSWORD_VALIDATION_RULE.required(
    'form:validation_messages.required',
  ),
});

export const ActivateUserValidationSchema = Yup.object().shape({
  otpCode: OTP_VALIDATION_RULE.required('form:validation_messages.required'),
  newPassword: PASSWORD_VALIDATION_RULE.required(
    'form:validation_messages.required',
  ),
  confirmNewPassword: STRING_VALIDATION_RULE.oneOf(
    [Yup.ref('newPassword'), ''],
    {
      key: 'form:validation_messages.invalid_value_with_label',
      values: {
        label:
          'auth:signin.steps.activate_account.form_fields.confirm_new_password',
      },
    },
  ).required('form:validation_messages.required'),
});

export const SignUpValidationSchema = Yup.object().shape({
  firstName: CRM_NAME_VALIDATION_RULE.min(NAME_MIN_LENGTH, {
    key: 'form:validation_messages.min_characters',
    values: {
      number: NAME_MIN_LENGTH,
    },
  })
    .max(CRM_CONTACT_NAME_MAX_LENGTH, {
      key: 'form:validation_messages.max_characters',
      values: {
        number: CRM_CONTACT_NAME_MAX_LENGTH,
      },
    })
    .required('form:validation_messages.required'),

  lastName: CRM_NAME_VALIDATION_RULE.min(NAME_MIN_LENGTH, {
    key: 'form:validation_messages.min_characters',
    values: {
      number: NAME_MIN_LENGTH,
    },
  })
    .max(CRM_CONTACT_NAME_MAX_LENGTH, {
      key: 'form:validation_messages.max_characters',
      values: {
        number: CRM_CONTACT_NAME_MAX_LENGTH,
      },
    })
    .required('form:validation_messages.required'),

  email: EMAIL_VALIDATION_RULE.required('form:validation_messages.required'),

  phoneNumber: PHONE_ASYNC_VALIDATION_RULE.required(
    'form:validation_messages.required',
  ),

  password: PASSWORD_VALIDATION_RULE.required(
    'form:validation_messages.required',
  ),

  passwordConfirm: STRING_VALIDATION_RULE.oneOf(
    [Yup.ref('password'), ''],
    'form:warnings.passwords_should_match',
  ).required('form:validation_messages.required'),

  accountType: NULLABLE_STRING_VALIDATION_RULE.oneOf([
    OnboardingEntryTypes.Contact,
    OnboardingEntryTypes.Organization,
  ]).required({
    key: 'form:validation_messages.required',
  }),

  keyRelationshipTemplateId: NULLABLE_STRING_VALIDATION_RULE.defined().when(
    'accountType',
    {
      is: OnboardingEntryTypes.Organization,
      then: (schema) => schema.required(),
    },
  ),
});

export const PhoneConfirmationValidationSchema = Yup.object().shape({
  code: OTP_VALIDATION_RULE.required('form:validation_messages.required'),
});

export const UpdatePhoneValidationSchema = Yup.object().shape({
  phone: PHONE_ASYNC_VALIDATION_RULE.required(
    'form:validation_messages.required',
  ),
});

export const TwoStepVerificationValidationSchema = Yup.object().shape({
  code: OTP_VALIDATION_RULE.required('form:validation_messages.required'),
});

export const AccountRecoveryRequestSchema = Yup.object().shape({
  currentStep: Yup.number(),
  // Step 1 - Information tab
  email: EMAIL_VALIDATION_RULE.when('currentStep', {
    is: 1,
    then: (schema) => schema.required('form:validation_messages.required'),
  }),
  phone: PHONE_ASYNC_VALIDATION_RULE.when('currentStep', {
    is: 1,
    then: (schema) => schema.required('form:validation_messages.required'),
  }),
});

export const ActivateAccountValidationSchema = Yup.object().shape({
  password: PASSWORD_VALIDATION_RULE.required(
    'form:validation_messages.required',
  ),
  passwordConfirm: STRING_VALIDATION_RULE.oneOf(
    [Yup.ref('password'), ''],
    'form:validation_messages.required',
  ).required('form:validation_messages.required'),
});

// Reset password schemas
export const ResetPasswordValidationSchema = Yup.object().shape({
  // TODO: username validation
  username: STRING_VALIDATION_RULE.when('currentStep', {
    is: ResetTabs.RequestResetPasswordUsername,
    then: (schema) => schema.required('form:validation_messages.required'),
  }),

  verificationCode: OTP_VALIDATION_RULE.when('currentStep', {
    is: ResetTabs.CodeVerification,
    then: (schema) => schema.required('form:validation_messages.required'),
  }),

  verificationSmsCode: OTP_VALIDATION_RULE.when('currentStep', {
    is: ResetTabs.RequestResetPasswordSMS,
    then: (schema) => schema.required('form:validation_messages.required'),
  }),

  selfieSrc: STRING_VALIDATION_RULE.when('currentStep', {
    is: ResetTabs.SelfieVerification,
    then: (schema) => schema.required('form:validation_messages.required'),
  }),

  newPassword: PASSWORD_VALIDATION_RULE.when('currentStep', {
    is: ResetTabs.ResetPassword,
    then: (schema) => schema.required('form:validation_messages.required'),
  }),

  confirmNewPassword: STRING_VALIDATION_RULE.oneOf(
    [Yup.ref('newPassword'), ''],
    'form:warnings.passwords_should_match',
  ).when('currentStep', {
    is: ResetTabs.ResetPassword,
    then: (schema) => schema.required('form:validation_messages.required'),
  }),
});

export const VerifyEmailValidationSchema = Yup.object().shape({
  otp: OTP_VALIDATION_RULE.required('form:validation_messages.required'),
});

export const ResetUsernameValidationSchema = Yup.object().shape({
  step: STRING_VALIDATION_RULE.required(),
  user: Yup.object().when('step', {
    is: 'user_data',
    then: (schema) =>
      schema.shape({
        name: STRING_VALIDATION_RULE.required(
          'form:validation_messages.required',
        ),
        email: EMAIL_VALIDATION_RULE.required(
          'form:validation_messages.required',
        ),
        clientGroup: STRING_VALIDATION_RULE.required(
          'form:validation_messages.required',
        ),
        dateOfBirth: NULLABLE_DATE_VALIDATION_RULE.required(
          'form:validation_messages.required',
        ),
      }),
  }),
  emailConfirmation: Yup.object().when('step', {
    is: 'email_confirmation',
    then: (schema) =>
      schema.shape({
        otp: OTP_VALIDATION_RULE.required('form:validation_messages.required'),
      }),
  }),
});
